<template>
  <div class="no-top-margin-all-descendants" style="height: 31.25rem;">
    <div style="height: inherit; position: relative; z-index: 98;">
      <l-map @update:zoom="zoomUpdate" @update:center="centerUpdate" @update:bounds="boundsUpdated"
        ref="metStationMap"
        :zoom="zoom"
        :center="get_map_center"
        :bounds="get_map_bounds"
        :options="mapOptions"
        >
        <l-tile-layer
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
        />
        <l-control position="topleft">
        </l-control>
        <l-marker
          v-if="stationLatLng.lat && stationLatLng.lng && showStationMarker"
          visible
          :icon="greenIcon"
          :lat-lng.sync="stationLatLng"
        >
        </l-marker>
        <l-marker
          v-if="projectLatLng.lat && projectLatLng.lng "
          visible
          :icon="redIcon"
          :lat-lng.sync="projectLatLng"
        >
        </l-marker>
        <l-control position="bottomright" class="map-legend-control">
          <div id='map-legend'>
            <div class="legend-container">
              <div class="d-flex  justify-content-flex mb-1">
                <img class="legend-marker" src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png" alt="Red Marker"> 
                <p class="ml-2 text-white"> Project Location</p>
              </div>
              <div v-if="showStationMarker" class="d-flex justify-content-center" >
                <img class="legend-marker" src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png" alt="Green Marker"> 
                <p class="ml-2 text-white"> Selected Station - {{ getStationName }}</p>
              </div>
            </div>
          </div>
        </l-control>
      </l-map>
    </div>
  </div>
</template>
<script>

import { LMap, LMarker, LTileLayer, LControl } from 'vue2-leaflet';
import { icon, latLngBounds, latLng } from 'leaflet';
export default {
  name: 'ProjectResourceMapModel',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
  },
  props: {
    stationData: {
      type: Object,
      required: false
    },
    projectPosition: {
      type: Object,
      required: false
    },
    metDataType: {
      type: String,
      required: false
    },
    hasCustomMetData: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      dataType: null,
      center: latLng(0, 0),
      stationLatLng: latLng(0, 0),
      projectLatLng: latLng(0, 0),
      selectedDataType: null,
      bounds: null,
      stationName: null,
      greenIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        shadowUrl: '/src/assets/img/marker-shadow.webp',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      redIcon: icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: '/src/assets/img/marker-shadow.webp',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      },
      zoom: 18,
      mapOptions: {
        attributionControl: false,
      }   
    };
  },
  mounted() {
    this.dataType = this.metDataType;
    this.stationLatLng.lat = this.stationData?.latitude;
    this.stationLatLng.lng = this.stationData?.longitude;
    this.projectLatLng = this.projectPosition;
    this.stationName = this.stationData?.name;
    this.center = this.projectLatLng;
  },
  methods: {
    resizeMapTiles(){
      setTimeout(() => {
        this.$refs.metStationMap.mapObject.invalidateSize(); 
      }, 100);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      this.resizeMapTiles();
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    }
  },  
  computed: {
    getStationName(){
      return this.stationData?.name;
    },
    get_map_bounds(){
      // If there are multiple markers(locations) being displayed on the map dynamically adjust the bounds
      // ref: https://leafletjs.com/reference.html#latlngbounds
      let coords =  latLngBounds([[this.projectLatLng.lat, this.projectLatLng.lng]]);
      if(this.showStationMarker){
        coords.extend([this.stationLatLng.lat, this.stationLatLng.lng]);
        return coords.pad(0.08);
      }
      return coords.pad(0.02);
    },
    get_map_center(){
      if(this.center) {
        let center =  latLngBounds([[this.projectLatLng.lat, this.projectLatLng.lng]]);
        if(this.showStationMarker)
          center.extend([this.stationLatLng.lat, this.stationLatLng.lng]);
        return center.getCenter();
      } else {      
        return latLng(0, 0);      
      }
    },
    showStationMarker(){
      return  this.dataType === 'MODELLED' ? false : true;
    }
  },
  watch: {
    dataType(newValue) {
      if (newValue === 'MODELLED' && this.stationData?.length > 0) {
        this.dataType = 'STATION';
        this.selectedDataType = 'STATION';
      }
    },
    stationData(newValue) {
      this.stationLatLng.lat = newValue.latitude;
      this.stationLatLng.lng = newValue.longitude;
    }
  }
};
</script>

<style scoped>
  .map-legend-control {
    padding: 0.313rem;
    border-radius: 0.188rem;
    border: 0.063rem solid #aaa;
    background: rgba(0, 0, 0, 0.5);
  }

  .legend-marker{
    height: 1.438rem;
  }

  .button-container > * {
    margin-top: 0; 
    position: relative;   
  }

  .loading-spinner {
    height: 1.25em;
    width: 1.25em;
    padding: 0 1.5rem;
  }

  .leaflet-layer {
      position: absolute;
      left: 0;
      top: 0;
  }

  .leaflet-control-container .leaflet-top.leaflet-left{
    display: flex;
    width: 100%;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control{
    height: fit-content;
  }
</style>
